<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { SHA256 } from "crypto-js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      searchQuery: "",
      page: 1,
      perPage: 5,
      pages: [],
      submited: false,
      spanView: false,
      data: {},
      fileres: [],
      faculty: {
        classe_id: null,
        group: "",
        teacher: null,
      },
      teachers: [],
      classTeacher: null,
      classes: [],
      title: "Nouvelle Classe",
      items: [
        {
          text: "Classes",
          href: "/",
        },
        {
          text: "Créer",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      classe_id: {
        required: helpers.withMessage("Veuillez choisir la classe", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getAllFaculties();
    this.getTeachers();
    this.getClasses();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.fileres);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.full_classe?.name?.toLowerCase().includes(search) ||
            data.department_professors_history[0]?.professor?.nom
              .toLowerCase()
              .includes(search) ||
            data.department_professors_history[0]?.professor?.prenom
              .toLowerCase()
              .includes(search) ||
            data.group?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },

  methods: {
    initFacultyCreation() {
      this.submited = true;
      this.createFaculty();
    },

    createFaculty() {
      this.spanView = true;
      this.data.annee_scolaire = localStorage.getItem("selectedYear");
      const self = this;
      this.v$.data.$touch();
      this.$store
        .dispatch("postRequest", { route: "api/departments ", data: this.data })
        .then(
          function (response) {
            self.v$.data.$reset();
            self.data = {};
            self.spanView = false;
            self.getAllFaculties();
            Swal.fire(
              response.data.message,
              "La filière a été créée avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    getClasses() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/classe ",
          data: this.data,
        })
        .then(
          function (response) {
            response.data.forEach((element) => {
              self.classes.push({
                value: element.id,
                label: element.name,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },

    getTeachers() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/professeur ",
          data: this.data,
        })
        .then(
          function (response) {
            response.data.searchedUsers.forEach((element) => {
              self.teachers.push({
                value: element.id,
                label: element.nom + " " + element.prenom,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },

    initFacultyUpdate() {
      this.submited = true;
      this.updateFiliere();
    },

    updateFiliere() {
      const self = this;
      const data = {
        classe_id: this.faculty.classe_id,
        professeur_id: this.faculty.teacher,
        group: this.faculty.group,
      };
      this.spanView = true;
      this.$store
        .dispatch("putRequest", {
          route: `/api/departments/${this.faculty.id}`,
          data: data,
        })
        .then(
          function (response) {
            self.v$.data.$reset();
            self.spanView = false;
            self.getAllFaculties();
            Swal.fire(response.data.message, "Succès!", "success");
          },
          function (error) {
            self.spanView = false;
            console.log(error);
          }
        );
    },

    getAllFaculties() {
      const self = this;
      this.$store
        .dispatch("getRequest", { route: "api/departments", data: this.data })
        .then(
          function (response) {
            self.fileres = response.data.filieres;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },
    navEncryptFiliere(filiere,style) {
      const filiereString1 = JSON.stringify(filiere);
      const encryptionKey1 = "Slim#9065";
      const encrypted1 = CryptoJS.AES.encrypt(filiereString1, encryptionKey1);
      const encryptedMessage1 = encrypted1.toString();
      localStorage.setItem("dataF", encryptedMessage1);

      const filiereString = JSON.stringify(filiere.id);
      const hash = SHA256(filiereString).toString();
      this.$router.push({ path: `/filiere/${hash}/${style}` });
    },
    async deleteFaculty(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette classe? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch("deleteRequest", {
              route: `api/departments/${id}`,
              data: this.data,
            })
            .then(
              function (response) {
                console.log(response);
                self.getAllFaculties();
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },

    getFaculty(data) {
      this.faculty = {
        id: data.id,
        classe_id: data.classe_id,
        group: data.group,
        teacher: data.department_professors_history[0]?.professor?.id,
      };
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.fileres.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input
                type="text"
                class="form-control"
                v-model="searchQuery"
                placeholder="Search..."
              />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary me-3"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Classe
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">Id</th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Classe
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Ecolage
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Titulaire
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="filiere in resultQuery" :key="filiere.id">
                  <th scope="row">{{ filiere.id }}</th>

                  <td>
                    {{ filiere.full_classe.name }}
                    {{ filiere.group?.toUpperCase() }}
                  </td>

                  <td>{{ filiere.full_classe.ecolage }} Fcfa</td>

                  <td
                    v-if="filiere.department_professors_history[0]?.professor"
                  >
                    {{
                      filiere.department_professors_history[0]?.professor?.nom
                    }}
                    {{
                      filiere.department_professors_history[0]?.professor
                        ?.prenom
                    }}
                  </td>

                  <td v-else class="text-danger">
                    <b>Aucun titulaire</b>
                  </td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        @click="navEncryptFiliere(filiere,1)"
                        class="link-success fs-15"
                        ><i class="ri-eye-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="navEncryptFiliere(filiere,2)"
                        class="link-primary fs-15"
                        ><i class="las la-hand-holding-usd"></i
                      ></a>
                      <a
                        data-bs-toggle="modal"
                        data-bs-target=".bs-faculty-update"
                        @click="getFaculty(filiere)"
                        class="link-secondary fs-15"
                        ><i class="ri-pencil-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteFaculty(filiere.id)"
                        class="link-danger fs-15"
                        ><i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initFacultyCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Nouvelle classe
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Classe</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="data.classe_id"
                          :class="{
                            'is-invalid':
                              (v$.data.classe_id.$error && data.classe_id) ||
                              (v$.data.classe_id.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          :options="classes"
                        />
                        <div
                          v-for="(item, index) in v$.data.classe_id.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.classe_id) ||
                              (v$.data.classe_id.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Groupe/Série</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          v-model="data.group"
                          placeholder="Groupe ou Série"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Professeur titulaire</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="data.teacher"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          :options="teachers"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-faculty-update"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateFacultyModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initFacultyUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="updateFacultyModal">Modifier</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Classe</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="faculty.classe_id"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          :options="classes"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Groupe/Série</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          v-model="faculty.group"
                          placeholder="Groupe ou Série"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Professeur titulaire</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="faculty.teacher"
                          :close-on-select="true"
                          :searchable="true"
                          :show-labels="false"
                          :options="teachers"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Modifier
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
